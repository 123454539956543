import React from 'react';
import {Grid, List, SortAlphaDown, SortAlphaDownAlt } from 'react-bootstrap-icons';
import { NavLink, useLocation } from "react-router-dom";
import { Nav, Navbar, Dropdown } from 'react-bootstrap';

function Header({props}){

    const loc = useLocation();

    const handleIdentifyDisplayClick = () => {
        props.setDisplayTypeTile(displayTypeTile => !displayTypeTile);
    }

    const handleNameSortClick = () => {
        props.setSortByCommonName(sortByCommonName => !sortByCommonName);
    }

    function getDropdownMenu () {

        switch (loc.pathname) {
            case "/identify":
                return(
                    <Dropdown.Menu key="dropdownMenu">
                          <Dropdown.Header>Display</Dropdown.Header>
                        <Dropdown.Item onClick={handleIdentifyDisplayClick}>
                            {props.displayTypeTile
                                    ?   <span><List/> List</span> 
                                    :   <span><Grid/> Grid</span>
                            }
                        </Dropdown.Item>   
                    </Dropdown.Menu>
                );
            case "/browse":
                return(
                    <Dropdown.Menu key="dropdownMenu">
                            <Dropdown.Header>Name Sort</Dropdown.Header>
                        <Dropdown.Item onClick={handleNameSortClick}>
                            {props.sortByCommonName
                                    ?   <span><SortAlphaDown/> Binomial</span> 
                                    :   <span><SortAlphaDownAlt/> Common</span>
                            }
                        </Dropdown.Item>   
                    </Dropdown.Menu>
                );
            case "/compare":
                    return(
                        <Dropdown.Menu key="dropdownMenu">
                                <Dropdown.Header>Name Sort</Dropdown.Header>
                            <Dropdown.Item onClick={handleNameSortClick}>
                                {props.sortByCommonName
                                        ?   <span><SortAlphaDown/> Binomial</span> 
                                        :   <span><SortAlphaDownAlt/> Common</span>
                                }
                            </Dropdown.Item>   
                        </Dropdown.Menu>
                );
            default:
                return(
                    <Dropdown.Menu disabled key="dropdownMenu">
                        <Dropdown.Item onClick={function(){alert("Contact: mich0550@protonmail.com")}}>About</Dropdown.Item>
                    </Dropdown.Menu>
                );
        }

    }

    const image = require(`../assets/mushicon.png`);

    return (
        <Navbar variant="light" className="bg-light navBarContainer border-bottom" fixed="top" >

            <Nav align="center">
                <Navbar.Brand className="ps-3 me-1">
                    <img
                    src={image}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="AgaricId: WA Mushroom Identifier"
                    />
                </Navbar.Brand>
                <Nav.Link as={NavLink} to="/identify" className="ps-2 pe-2">Identify</Nav.Link>
                <Nav.Link as={NavLink} to="/browse"  className="ps-1 pe-2">Browse</Nav.Link>
                <Nav.Link as={NavLink} to="/compare"  className="ps-1 pe-2">Compare</Nav.Link>
            </Nav>

            <Dropdown align="end" className="pe-3">
                <Dropdown.Toggle variant="secondary-outline" />
                {getDropdownMenu()}
            </Dropdown>

    </Navbar>
    );
}

export default Header;