import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Identify from "./components/Identify";
import IdentifyResults from "./components/IdentifyResults";
import Browse from "./components/Browse";
import Layout from "./components/Layout";
import Landing from "./components/Landing";
import Compare from "./components/Compare";

function App() {

  const [ fungi, setFungi ] = useState([]);
  const [ parts, setParts ] = useState([]);
  const [ traits, setTraits ] = useState([]);
  const [ values, setValues ] = useState([]);
  const [ fungusValues, setfungusValues ] = useState([]);
  const [ selectedValues, setSelectedValues ] = useState([]);
  const [ results, setResults ] = useState([]);
  const [ fungusInfoId, setFungusInfoId ] = useState(0); 
  const [ showLanding, setShowLanding] = useState(true);
  const [ displayTypeTile, setDisplayTypeTile ] = useState(false);
  const [ sortByCommonName, setSortByCommonName ] = useState(true);
  const [ remoteApi ] = useState("https://agaricid-api.azurewebsites.net/api/");

  const props = 
  {
    fungi, setFungi, 
    parts, setParts, 
    traits, setTraits, 
    values, setValues, 
    fungusValues, setfungusValues,
    selectedValues, setSelectedValues, 
    results, setResults,
    fungusInfoId, setFungusInfoId, 
    showLanding, setShowLanding, 
    displayTypeTile, setDisplayTypeTile, 
    sortByCommonName, setSortByCommonName,
    remoteApi
  }

  useEffect(() => {

    const useLocal = false;
    let apiAddress = "https://localhost:7244/api/"
    if (!useLocal) {apiAddress = remoteApi}

    axios
      .get(apiAddress + "Fungus", { crossdomain: true })
      .then((response) => {setFungi(response.data)});

    axios
      .get(apiAddress + "Part", { crossdomain: true })
      .then((response) => {setParts(response.data)});

    axios
      .get(apiAddress + "Trait", { crossdomain: true })
      .then((response) => {setTraits(response.data)});

    axios
      .get(apiAddress + "Value", { crossdomain: true })
      .then((response) => {setValues(response.data)});

    axios
      .get(apiAddress + "fungusValue", { crossdomain: true })
      .then((response) => {setfungusValues(response.data)});

  },[remoteApi])

  return (
    <div className={showLanding ? "brightnessFilter" : ""}>
      <div className={showLanding ? "landingBackground" : "bg-white"}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout props={props}/>}>
              <Route index element={<Landing props={props}/>} />
              <Route path="/identify" element={<Identify props={props}/>}/>
              <Route path="/results" element = {<IdentifyResults props={props} />} />
              <Route path="/browse" element = {<Browse props={props} />} />
              <Route path="/compare" element = {<Compare props={props} />} />
              <Route path="*" element={<Landing props={props}/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
