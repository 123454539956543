import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "./Header";

const Layout = ({props}) => {

  return (
    <Container>
      <Row>
        <Col>
          {<Header props={props}/>}
        </Col>
      </Row>
        <Row >
          <Col className="p-0"
            sm={props.showLanding ? { span: 12, offset: 0 } : { span: 12, offset: 0}}
            md={props.showLanding ? { span: 12, offset: 0 } : { span: 10, offset: 1}}
            xl={props.showLanding ? { span: 12, offset: 0 } : { span: 8, offset: 2}}
          >
            {<Outlet />}
          </Col>
        </Row>
    </Container>
  )
};

export default Layout;
