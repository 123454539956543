import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import { Link, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer({ props, getResults }) {

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  function handleReset(){
      document.querySelectorAll('.tile').forEach(e => e.classList.remove('selectedTile'));
      props.setSelectedValues([]);
      props.setResults([]);
      handleClose();
  }

  const location = useLocation();

  const getFooterContent = () => {
    switch(String(location.pathname)) {
      case '/results':
        return(
            <div  className="text-center">
              <Link to="/identify">
                  <Button variant="outline-primary" size="md" key="returnButton">
                      Return to Search
                  </Button>
                </Link>
              </div>
        );
      case '/identify':
        return(
          <Stack direction="horizontal" gap={2}>
            <div>
                <Button variant="outline-primary" size="md" key="resetButton" onClick={handleShow}>
                    Reset
                </Button>
            </div>    
            <div className="ms-auto">
                <Link to="/results">
                  <Button variant="outline-primary" size="md" onClick={function(){getResults()}} key="resultsButton">
                      View Results
                  </Button>
                </Link>
              </div>
            </Stack>
        );
      default:
        return(null);
    }
  }

  return (
    <div className="footerChild position-sticky">

      <Container>
        <Row>
          <Col xs={{ span: 10, offset: 1}} sm={{ span: 8, offset: 2 }}>
            { getFooterContent() }
          </Col>
        </Row>
      </Container>

      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset</Modal.Title>
        </Modal.Header>
          <Modal.Body>Remove all current selections?</Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={handleReset}>
              Yes
            </Button>
          <Button variant="outline-primary" onClick={handleClose}>
              No
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Footer;
