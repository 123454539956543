import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from './Image';
import Footer from './Footer';
import { Card, ListGroup, Spinner, } from 'react-bootstrap';
import {useEffect} from 'react';


function Identify({props}){

  useEffect(() => {
    props.setShowLanding(false);
  })

  // const showImages = true;

  function getResults() {
    props.setResults([]);
    // get all fungus values
    const current = props.fungusValues.filter(fv => props.selectedValues.includes(fv.valueId))
    // filter fungus values by valueid in selected values
    //console.log("current:", current)
    let fvMatches = []
    current.forEach(fv => {
      // add object if not exists
      if (!fvMatches.find(matches => matches.fungusId === fv.fungusId)) {
        fvMatches.push({"fungusId": fv.fungusId, "valueIds": [fv.valueId] })
      }
      else {
        fvMatches.find(matches => matches.fungusId === fv.fungusId).valueIds.push(fv.valueId)
      }
    })

    function getFungiName(fungusId){
      let name = props.fungi[props.fungi.findIndex((f)=>f.id === fungusId)].commonName;
      //console.log(fungusId, name)
      return name
    }

    const sortedFvMatches = fvMatches.sort((a, b) => {
      if (a.valueIds.length > b.valueIds.length) return -1;
      if (a.valueIds.length < b.valueIds.length) return 1;
      if (a.valueIds.length === b.valueIds.length) {
        //console.log("match:", a.valueIds.length, b.valueIds.length)
        if (getFungiName(a.fungusId) > getFungiName(b.fungusId)) return 1;
        if (getFungiName(a.fungusId) < getFungiName(b.fungusId)) return -1;
      }
      return 0;
    });

    props.setResults(sortedFvMatches)
  }

    const handleClick = event =>{
        //console.log("Clicked:", event.currentTarget)
        var valueId = Number(event.currentTarget.id);
        if (props.selectedValues.includes(valueId)) {
            //console.log("remove", valueId)
            props.setSelectedValues(selectedValues => selectedValues.filter(item => item !== valueId));
            event.currentTarget.classList.remove('highlighted');
        }
        else {
            //console.log("add", valueId)
            props.setSelectedValues(selectedValues => [...selectedValues, valueId]);
            event.currentTarget.classList.add('highlighted');
        }
    }

    // filters values to those only in fungusValues table
    function valueFilter(value){
      const valueFound = props.fungusValues.some(fv => {
        if (fv.valueId === value.id) {
          return true;
        }
      return false;
      });
      return valueFound;
    };

    if (props.parts.length === 0) {
      return (
          <div className="d-flex justify-content-center pt-5 mt-5">
              <Spinner animation="border"/>
          </div> 
      )}

    return(
    <div className="bodyParent pt-5 mt-4">
    <div className='bodyChild'>
      <Tabs defaultActiveKey="Cap" id="Tabs" className="mb-2" justify transition={false} key="Tabs">
        {props.parts.map((part) => {

          return (
            <Tab eventKey={part.name} title={part.name} key={part.name}>

            <Container>
              <Row>
                <Col 
                  xs={(props.displayTypeTile) ? null : {span:10, offset:1} }
                  sm={(props.displayTypeTile) ? null : {span:8, offset:2} }
                >

              {part.traits.map((trait) => {

                if (props.displayTypeTile) {
                  return (

                    <Card key={trait.id} className="mt-3">
                      <Card.Header>
                        {part.name} {trait.name}
                      </Card.Header>
                        <div className="d-flex flex-wrap justify-content-center" >

                          {trait.values.filter(valueFilter).map((value) => {
                              // return Tiles
                              return (                    
                                <div  key={value.id} id={value.id} onClick={handleClick}
                                  className={ (props.selectedValues.includes(value.id))
                                    ?"tile highlighted":"tile"} >

                                  <div className="tileText"> {value.name} </div>
                                  <div> {Image("value", value.id, 200)} </div>
                                </div>
                              );
                          })}

                        </div>
                      </Card>
                )} 
                else {
                  //return Bars /
                  return (

                    <Card key={trait.id} className="mt-3">
                      <Card.Header>
                        {part.name} {trait.name}
                      </Card.Header>
                      <ListGroup variant="flush">

                        {  trait.values.filter(valueFilter).map((value) => {
                                  return (
                                      <ListGroup.Item key={value.id} id={value.id} onClick={handleClick}
                                        className={ (props.selectedValues.includes(value.id))
                                        ? "highlighted" : ""}>
                                        <Container><Row>
                                          <Col xs={{ span:9, offset:3}} sm={{ span:8, offset:4 }}>
                                            <span>{Image("value", value.id, 30)}</span>
                                            <span className="ps-2">{value.name}</span>
                                          </Col>
                                        </Row></Container>
                                      </ListGroup.Item>
                                    );
                        })}

                      </ListGroup>
                    </Card>

                  )}
              })}
              </Col></Row></Container>
            </Tab>
          );
        })}
      </Tabs>
      </div>
      {<Footer props={props} getResults={getResults} />}
      </div>
    )

}

export default Identify;