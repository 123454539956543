import Table from "react-bootstrap/Table";
import axios from "axios";
import React, {useState, useEffect} from "react";
import Spinner from 'react-bootstrap/Spinner';

// build an object that is structured according to part trait value for use as table
function IdentifyTable({props, fungusId}){

    const [ gbf, setGbf ] = useState([]);
    const [ isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        axios
          .get( props.remoteApi + "/FungusValue/GetByFungus/" + fungusId, { crossdomain: true })
          .then((response) => { 
            setGbf(response.data); 
            setIsLoading(false);
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.remoteApi]);


    if (isLoading){
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border"/>
            </div> 
        )
      }
    let arrFungus = [];

    gbf.map(fv => arrFungus.push(getObjRow(fv.valueId)));

    arrFungus.sort((a, b) => {
        if (a.partOrder > b.partOrder ) return 1;
        if (a.partOrder < b.partOrder ) return -1;
        if (a.traitOrder > b.traitOrder ) return 1;
        if (a.traitOrder < b.traitOrder ) return -1;
        // if (a.valueOrder > b.valueOrder ) return 1;
        // if (a.valueOrder < b.valueOrder ) return -1;
        return 0;
    });

    function getObjRow(valueId) {
        let objValue = props.values.filter(value => value.id === valueId )[0];
        let objTrait = props.traits.filter(trait => trait.id === objValue.traitId)[0];
        let objPart = props.parts.filter(part => part.id === objTrait.partId)[0];
        return { 
        partId:objPart.id, partName:objPart.name, partOrder:objPart.displayOrder,
        traitId:objTrait.id, traitName:objTrait.name, traitOrder:objTrait.displayOrder,
        valueId:objValue.id, valueName:objValue.name, valueOrder:objValue.displayOrder        
        }
    }

    let partIndex = 0;
    let partCount = [1];
    let prevPartOrder = arrFungus[0].partOrder;
    let traitIndex = 0;
    let traitCount = [1];
    let prevTraitOrder = arrFungus[0].traitOrder;

  for (var i = 1; i < arrFungus.length; i++){
        if (arrFungus[i].partOrder === prevPartOrder) {partCount[partIndex]++;}
        else {partCount.push(1); partIndex++;}
        prevPartOrder = arrFungus[i].partOrder;

        if (arrFungus[i].traitOrder === prevTraitOrder) {traitCount[traitIndex]++;}
        else {traitCount.push(1); traitIndex++;}
        prevTraitOrder = arrFungus[i].traitOrder;
    }

    function returnPartCell(index){
        // check first
        if (index === 0)
        {
            let partCount = 0;
            for (let i = index; i<arrFungus.length-1; i++){
            if(arrFungus[index].partId === arrFungus[i].partId) 
                {partCount++}
            else break;
            }
            return(<td rowSpan={partCount}>{arrFungus[index].partName}</td>)
        }
        // check last
        else if (index === arrFungus.length-1)
        {
            if (arrFungus[index].partName === arrFungus[index-1].partName) 
                {return ("")}
            else {return( <td>{arrFungus[index].partName}</td>)}
        }
        // check middle
        else
        {
            // if previous is different
            if (arrFungus[index].partId !== arrFungus[index-1].partId)
            {
            let partCount = 0;
            for (let i = index; i<arrFungus.length; i++){
                if(arrFungus[index].partId === arrFungus[i].partId)
                {partCount++}
                else break;
            }
            return(<td rowSpan={partCount}>{arrFungus[index].partName}</td>)
            }
            // if previous is same
            else { return ""}
        }
    }

    function returnTraitCell(index){
        // check first
        if (index === 0)
        {
            let traitCount = 0;
            for (let i = index; i<arrFungus.length-1; i++){
            if(arrFungus[index].traitId === arrFungus[i].traitId)
                {traitCount++}
            else break;
            }
            return(<td rowSpan={traitCount}>{arrFungus[index].traitName}</td>)
        }
        // check last
        else if (index === arrFungus.length-1)
        {
            if (arrFungus[index].traitName === arrFungus[index-1].traitName) {return ("")}
            else {return( <td>{arrFungus[index].traitName}</td>)}
        }
        // check middle
        else
        {
            // if previous is different
            if (arrFungus[index].traitId !== arrFungus[index-1].traitId)
            {
            let traitCount = 0;
            for (let i = index; i<arrFungus.length; i++){
                if(arrFungus[index].traitId === arrFungus[i].traitId)
                {traitCount++}
                else break;
            }
            return(<td rowSpan={traitCount}>{arrFungus[index].traitName}</td>)
            }
            // if previous is same
            else { return ""}
        }
    }

    function returnValueCell(index) {
        if(props.selectedValues.includes(arrFungus[index].valueId))
        { return (<td className="text-center highlightedCell">{arrFungus[index].valueName}</td>) }
        else
        { return (<td className="text-center">{arrFungus[index].valueName}</td>) } 
    }

    function getRow(index) {
        return(
        <tr key={arrFungus[index].valueId}>
            {returnPartCell(index)}
            {returnTraitCell(index)}
            {returnValueCell(index)}
        </tr>
        )
    }

    return(
        <Table size="sm">
            <thead>
                <tr>
                <th></th>
                <th></th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {arrFungus.map((row, index) => {
                return(
                    getRow(index)
                ) 
                })}
            </tbody>
        </Table>
    );
}

export default IdentifyTable;