import React, {useState} from 'react';
import { Card, ListGroup, Badge } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Image from './Image';
import Footer from './Footer';
import IdentifyTable from './IdentifyTable';


function IdentifyResults({props}) {

  const[ showInfo, setShowInfo ] = useState(false);
  const[ showId, setShowId ] = useState(0)
  
  function handleShowInfo (fungiId) {
    setShowId(fungiId)
    setShowInfo(true);
  }

  function handleCloseInfo () {
    setShowId(0);
    setShowInfo(false);
  }

  const resultsFungusInfo = [];
  props.results.map(fv => fv.fungusId)
    .forEach(fungusId => resultsFungusInfo
      .push(props.fungi.find(fungus => fungus.id === fungusId)));

  return(

    <div className="bodyParent pt-5 mt-4">
      <div id="resultsBody" key="resultsBody" className="bodyChild">

      <Container>
        <Row><Col md={{offset:2, span: 8}}>
            <Card>
              <Card.Header>
                Identify Results
              </Card.Header>
              <ListGroup variant="flush">

                {props.results.length === 0 ? <div className="w-100 text-center p-4">No Results. Please revise your selections.</div> :
              
                  resultsFungusInfo.map(filteredFungus => (

                    <ListGroup.Item action key={filteredFungus.id} onClick={() => handleShowInfo(filteredFungus.id)}>
                    <Container className="dflex">
                <Row className="align-items-center align-middle">
                          <Col className="text-end" xs={{span:3, offset:0}} sm={{span:3, offset: 1}}>
                            {Image("fungus", filteredFungus.id, 60)}
                          </Col>
                          <Col className="align-middle text-start">
                            <div className="fw-bold">
                              {filteredFungus.commonName}
                              <Badge bg="light" text="dark float-end">
                                {props.results.find(resultsFungi => resultsFungi.fungusId === filteredFungus.id).valueIds.length}
                              </Badge>
                            </div>
                            <div className="fst-italic">{filteredFungus.binomialName}</div>

                          </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                ))}

                {/* </tbody>
              </Table> */}
              </ListGroup>
            </Card>
            </Col>
                    </Row>
                    </Container>

            {props.fungi.filter(fungus => showId === (fungus.id)).map(filteredFungus => (
              <Modal show={showInfo} onHide={handleCloseInfo} key={filteredFungus.id}>
                <Modal.Header closeButton className="bg-light">
                  <Modal.Title>
                  <span>{filteredFungus.commonName}</span>
                  <br/>
                  <h6 className="fst-italic">{filteredFungus.binomialName}</h6>
                  </Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                  <Container>

                    <Row><Col>
                      <div className="imgContainer">
                        {Image("fungus", filteredFungus.id, 250)}
                      </div>
                    </Col></Row>

                    <Row><Col>
                      {<IdentifyTable props={props} fungusId={filteredFungus.id}/>}
                    </Col></Row>

                  </Container>
                </Modal.Body>
              </Modal>
            ))}

      </div>
      {<Footer />}
    </div>
  )
}

export default IdentifyResults;