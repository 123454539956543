import React from "react";
import assets from "../assets/assets.json"
import {Row, Col, Container} from "react-bootstrap";

function Image (type, id, dimension) {

  const blankValues = false;

  const getCaption = (filename) => {
    let item = assets.find(item => item.file === filename)
    if (item === undefined) {return null}
    return <a href={item.link} className="caption"> © {item.owner} (CC BY-NC) </a>
  }

  try {
    let filename = String(id);
    while (filename.length < 3) {filename = "0" + filename};
    switch (type) {
      case "fungus":
        if (dimension === 250) {
          filename = "fp" + filename + "-250px.png";
          const image = require(`../assets/profiles/250/${filename}`);
          return (

            <Container>
              <Row>
                <Col className="text-center">
                  <img key="img" src={image} height={dimension} width={dimension} alt={type} className="rounded"/>
                 </Col>
              </Row>
              <Row>
                <Col className="text-center caption">
                  <span bg="light" text="grey" key="info">
                    {getCaption(filename)}{''}
                  </span>
                </Col>
              </Row>
            </Container>
    
        )
        }
        else if (dimension === 60) filename = "profiles/060/fp" + filename + "-060px.png";
        else  filename = "profiles/fp" + filename + ".png";
        break;
      case "value":
        // filename = "tv" + filename + ".png";
        // filename = "tv001-160.png"
        filename = "tiles-160/tv" + filename + "-160.png";
        if (blankValues === true) filename = "blank.png";
        break;
      default:
        filename = "blank.png";
    }
    const image = require(`../assets/${filename}`);
    return <img src={image} height={dimension} width={dimension} alt={type} className="rounded"/>;
  }
  catch(error) {
    console.log("Error finding image");
    console.log(error)
    const image = require(`../assets/imageNotFound.png`);
    return <img src={image} height={dimension} width={dimension} alt="not found" />;
  }

};

export default Image;