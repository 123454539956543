import Table from "react-bootstrap/Table";
import Image from "./Image";




// build an object that is structured according to part trait value for use as table
function CompareTable ({props, fungusIdOne, fungusIdTwo}){


    let fungusOneData = props.fungusValues.filter(fv => fv.fungusId === fungusIdOne)
    let fungusTwoData = props.fungusValues.filter(fv => fv.fungusId === fungusIdTwo)

    let fungusOneObject = [];
    let fungusTwoObject = [];


    const getObjRow = (valueId) => {
        let objValue = props.values.filter(value => value.id === valueId )[0];
        let objTrait = props.traits.filter(trait => trait.id === objValue.traitId)[0];
        let objPart = props.parts.filter(part => part.id === objTrait.partId)[0];
        return { 
            partId:objPart.id, partName:objPart.name, partOrder:objPart.displayOrder,
            traitId:objTrait.id, traitName:objTrait.name, traitOrder:objTrait.displayOrder,
            valueId:objValue.id, valueName:objValue.name, valueOrder:objValue.displayOrder
        }
    }

    const sortObject = (fungusObject) => {
        fungusObject.sort((a, b) => {
            if (a.partOrder > b.partOrder ) return 1;
            if (a.partOrder < b.partOrder ) return -1;
            if (a.traitOrder > b.traitOrder ) return 1;
            if (a.traitOrder < b.traitOrder ) return -1;
            return 0;
        })
    }

    fungusOneData.map(fv => fungusOneObject.push(getObjRow(fv.valueId)));
    fungusTwoData.map(fv => fungusTwoObject.push(getObjRow(fv.valueId)));

    sortObject(fungusOneObject);
    sortObject(fungusTwoObject);

    const returnPartCell = (index) => {
        // check first
        if (index === 0) {
            let partCount = 0;
            for (let i = index; i<fungiBothObject.length-1; i++){
                if(fungiBothObject[index].partId === fungiBothObject[i].partId) {partCount++}
                else break;}
            return(<td rowSpan={partCount}>{fungiBothObject[index].partName}</td>)
        }
        // check last
        else if (index === fungiBothObject.length-1) {
            if (fungiBothObject[index].partName === fungiBothObject[index-1].partName) {return ("")}
            else {return( <td>{fungiBothObject[index].partName}</td>)}
        }
        // check middle
        else {
            // if previous is different
            if (fungiBothObject[index].partId !== fungiBothObject[index-1].partId) {
                let partCount = 0;
                for (let i = index; i<fungiBothObject.length; i++){
                    if(fungiBothObject[index].partId === fungiBothObject[i].partId) {partCount++}
                    else break;}
                return(<td rowSpan={partCount}>{fungiBothObject[index].partName}</td>)
            }
            // if previous is same
            else { return ""}
        }
    }

    const returnTraitCell = (index) => {
        // check first
        if (index === 0) {
            let traitCount = 0;
            for (let i = index; i<fungiBothObject.length-1; i++){
                if(fungiBothObject[index].traitId === fungiBothObject[i].traitId)
                    {traitCount++}
                else break;
                }
            return(<td rowSpan={traitCount}>{fungiBothObject[index].traitName}</td>)
        }
        // check last
        else if (index === fungiBothObject.length-1) {
            if (fungiBothObject[index].traitName === fungiBothObject[index-1].traitName) {return ("")}
            else {return( <td>{fungiBothObject[index].traitName}</td>)}
        }
        // check middle
        else {
            // if previous is different
            if (fungiBothObject[index].traitId !== fungiBothObject[index-1].traitId) {
                let traitCount = 0;
                for (let i = index; i<fungiBothObject.length; i++){
                    if(fungiBothObject[index].traitId === fungiBothObject[i].traitId)
                    {traitCount++}
                    else break;
            }
            return(<td rowSpan={traitCount}>{fungiBothObject[index].traitName}</td>)
            }
            // if previous is same
            else { return ""}
        }
    }

    const returnValueCell = (index) => {
        return(
            <td key={"value" + index + "one"} 
                className={ fungiBothObject[index].valueName === fungiBothObject[index].valueNameTwo
                                ?   "text-center highlightedCell"
                                :   "text-center"}>
                {fungiBothObject[index].valueName}</td>)
    }

    const returnValueCellTwo = (index) => {
        return(
            <td key={"value" + index + "two"} 
                className={ fungiBothObject[index].valueName === fungiBothObject[index].valueNameTwo
                                ?   "text-center highlightedCell"
                                :   "text-center"}>
                {fungiBothObject[index].valueNameTwo}</td>)
    }

    const getRow = (index) => {
        return(
        <tr key={String(fungiBothObject[index].valueId + " " + fungiBothObject[index].valueIdTwo)}>
            {returnPartCell(index)}
            {returnTraitCell(index)}
            {returnValueCell(index)}
            {returnValueCellTwo(index)}
        </tr>
        )
    }

    const fungiBothObject = [];

    // iterate over unique trait ids, create array
    let uniqueTraits = [];
    fungusOneObject.forEach(item => {if(!uniqueTraits.includes(item.traitId)){uniqueTraits.push(item.traitId)}})
    fungusTwoObject.forEach(item => {if(!uniqueTraits.includes(item.traitId)){uniqueTraits.push(item.traitId)}})

    // within each, send an array of fungusOneValues and fungusTwoValues to another function
    uniqueTraits.forEach( uniqueTrait => {

        // reconstruct fungiBothObject
        const getNewObject = (valueIdOne, valueIdTwo) => {

            let newObject = Object.assign({}, {});
            // copy part and trait data from fungusOneObject or fungusTwoObject
            if (fungusOneObject.map(fungus => fungus.traitId).includes(uniqueTrait)) { 
                newObject = Object.assign({}, fungusOneObject.find(fungus => fungus.traitId === uniqueTrait))
            }
            else { 
                newObject = Object.assign({}, fungusTwoObject.find(fungus => fungus.traitId === uniqueTrait))
            }
                
            // update/add key values pairs
            if (valueIdOne > 0) { 
                newObject["valueId"] = valueIdOne;
                let valueName = props.values.filter(value => value.id === valueIdOne).map(item => item.name)[0];
                newObject["valueName"] = valueName;
            }
            else {
                newObject["valueId"] = 0;
                newObject["valueName"] = "-";
            }
            if (valueIdTwo > 0) { 
                newObject["valueIdTwo"] = valueIdTwo;
                let valueNameTwo = props.values.filter(value => value.id === valueIdTwo).map(item => item.name)[0];
                newObject["valueNameTwo"] = valueNameTwo;
            }
            else {
                newObject["valueIdTwo"] = 0;
                newObject["valueNameTwo"] = "-";
            }

            return newObject;
        }

        let valuesOne = [];
        fungusOneObject.filter(item => item.traitId === uniqueTrait).map(item => valuesOne.push(item.valueId))
        let valuesTwo = [];
        fungusTwoObject.filter(item => item.traitId === uniqueTrait).map(item => valuesTwo.push(item.valueId))

        // make same length
        if (valuesOne.length > valuesTwo.length)
            {while(valuesTwo.length !== valuesOne.length){valuesTwo.push(0)}}
        else if (valuesTwo.length > valuesOne.length)
            {while(valuesOne.length !== valuesTwo.length){valuesOne.push(0)}}

        //reorder to push matches to top
        valuesOne.forEach(item =>  {
            if (valuesTwo.includes(item)) {
                valuesOne = valuesOne.filter(val => val !== item);
                valuesOne.unshift(item);
                valuesTwo = valuesTwo.filter(val => val !== item);
                valuesTwo.unshift(item);
            }
        })

        for(let i = 0; i<valuesOne.length; i++) {
            let newObject = getNewObject(valuesOne[i], valuesTwo[i]);
            fungiBothObject.push(newObject);
        }
    })

    sortObject(fungiBothObject);

    return(
        <Table size="sm" className="resultsTable">
            <tbody>
                <tr>
                <td></td>
                <td></td>
                <td key="fungusOneHeader" className="text-center">
                    {Image('fungus', fungusIdOne, 100)}
                    <br/>
                    <div className="fw-bold">{ props.fungi.filter(fungus => fungusIdOne === (fungus.id)).map(filteredFungus => filteredFungus.commonName) }</div>
                    <div className="fst-italic">{ props.fungi.filter(fungus => fungusIdOne === (fungus.id)).map(filteredFungus => filteredFungus.binomialName) }</div>                    
                </td>
                <td key="fungusTwoHeader" className="text-center">
                    {Image('fungus', fungusIdTwo, 100)}<br/>
                    <div className="fw-bold">{ props.fungi.filter(fungus => fungusIdTwo === (fungus.id)).map(filteredFungus => filteredFungus.commonName) }</div>
                    <div className="fst-italic">{ props.fungi.filter(fungus => fungusIdTwo === (fungus.id)).map(filteredFungus => filteredFungus.binomialName) }</div>
                </td>
                </tr>


                {fungiBothObject.map((row, index) => {
                return(
                    getRow(index)
                ) 
                })}
            </tbody>
        </Table>
    );
}

export default CompareTable;