import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button, Card, ListGroup, InputGroup, Form, Spinner} from "react-bootstrap";
import CompareTable from "./CompareTable";
import Image from "./Image";
import { Backspace } from 'react-bootstrap-icons';



function Compare ({props}) {

    useEffect(() => {
        props.setShowLanding(false);
    })

    const [searchTermOne, setSearchTermOne] = useState("");
    const [searchResultsOne, setSearchResultsOne] = useState([]);
    const [selectionOne, setSelectionOne] = useState(0);

    const [searchTermTwo, setSearchTermTwo] = useState("");
    const [searchResultsTwo, setSearchResultsTwo] = useState([]);
    const [selectionTwo, setSelectionTwo] = useState(0); 

    const [showCompare, setShowCompare] = useState(false);

    useEffect(() => {

        const resultsOne = props.fungi.filter(fungus => 
            fungus.commonName.toLowerCase().includes(searchTermOne.toLowerCase()) ||
            fungus.binomialName.toLowerCase().includes(searchTermOne.toLowerCase())
        );

        const resultsTwo = props.fungi.filter(fungus => 
            fungus.commonName.toLowerCase().includes(searchTermTwo.toLowerCase()) ||
            fungus.binomialName.toLowerCase().includes(searchTermTwo.toLowerCase())
        );

        let sortedResultsOne;
        if(props.sortByCommonName){
            sortedResultsOne = resultsOne.sort((a, b) => {
                if (String(a.commonName) < String(b.commonName)) return -1;
                if (String(a.commonName) > String(b.commonName)) return 1;
                return 0;
            });
        }
        else {
            sortedResultsOne = resultsOne.sort((a, b) => {
                if (String(a.binomialName) < String(b.binomialName)) return -1;
                if (String(a.binomialName) > String(b.binomialName)) return 1;
                return 0;
            });
        }

        let sortedResultsTwo;
        if(props.sortByCommonName){
            sortedResultsTwo = resultsTwo.sort((a, b) => {
                if (String(a.commonName) < String(b.commonName)) return -1;
                if (String(a.commonName) > String(b.commonName)) return 1;
                return 0;
            });
        }
        else {
            sortedResultsTwo = resultsTwo.sort((a, b) => {
                if (String(a.binomialName) < String(b.binomialName)) return -1;
                if (String(a.binomialName) > String(b.binomialName)) return 1;
                return 0;
            });
        }

        setSearchResultsOne(sortedResultsOne)
        setSearchResultsTwo(sortedResultsTwo)
    
    },[searchTermOne, searchTermTwo, props.fungi, props.sortByCommonName]);

    const handleChangeOne = (event) => {
        setSearchTermOne(event.target.value);
    };

    const handleChangeTwo = (event) => {
        setSearchTermTwo(event.target.value);
    };

    const handleClickOne = event =>{
        if (selectionOne === 0) { setSelectionOne(Number(event.currentTarget.id))}
        else { setSelectionOne(0) }
    }

    const handleClickTwo = event =>{
        if (selectionTwo === 0) { setSelectionTwo(Number(event.currentTarget.id))}
        else { setSelectionTwo(0) };
    }

    const handleShowCompare = () => {
        setShowCompare(true);
    }

    const handleCloseCompare = () => {
        setShowCompare(false);
    }

    const resetSearchTermOne = () => {
        setSearchTermOne("");
        setSelectionOne(0);
      }

      const resetSearchTermTwo = () => {
        setSearchTermTwo("");
        setSelectionTwo(0);
      }

    const CompareButton = () => {
        if (selectionOne>0 && selectionTwo>0 ) {
            return(<Button variant="outline-primary" key="compareButton" onClick={() => handleShowCompare()}>Compare</Button>);
        }
        return(<Button variant="outline-primary" key="compareButton" className="hidden">Compare</Button>);
    }

    // if (props.fungusValues.length === 0) {
    //     return (
    //         <div className="d-flex justify-content-center pt-5 mt-5">
    //             <Spinner animation="border"/>
    //         </div> 
    //     )}

    const getLoader = () => {
        return (
            <div className="d-flex justify-content-center p-3">
                <Spinner animation="border" />
            </div> 
        )
        }

    return(
        <div>
            <div key="resultsBodyOne" className="d-flex flex-column flex-lg-row justify-content-lg-center bg-white pt-5 mt-4">
                <div sm={{span:12}} lg={{span: 6}} className="w-100">
                    <Container><Row><Col>
                        <Card id="tableOne" className="mb-3">
                            <Card.Header>
                                <Container className="dflex">
                                    <Row className="align-items-center">
                                    <Col className="text-start">
                                        Select
                                    </Col>
                                    <Col className="align-items-center">
                                        <InputGroup size="sm">
                                            <Form.Control 
                                                aria-label="Small" 
                                                id="searchBoxOne"
                                                placeholder="Filter" 
                                                key="searchBoxOne"
                                                value={searchTermOne} 
                                                onChange={handleChangeOne}
                                                >
                                            </Form.Control>
                                            <InputGroup.Text  onClick={resetSearchTermOne} >
                                                {<Backspace/>}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                    </Row>
                                </Container>
                            </Card.Header>
                                <ListGroup variant="flush" id="searchResultsTableOne">

                                {   props.fungi.length === 0
                                    ? getLoader()
                                    :   searchResultsOne.map(filteredFungus => (
                                            <ListGroup.Item action key={"1" + filteredFungus.id}  id={filteredFungus.id} onClick={handleClickOne}
                                                className={(selectionOne === filteredFungus.id) ? "selected highlighted border-bottom-0" : (selectionOne > 0 ) ? "hidden" : "" }>
                                                <Container>
                                                    <Row className="align-items-center align-middle">
                                                        <Col className="text-end" xs={{span:3, offset:0}} sm={{span:3, offset: 1}} md={{span:3, offset:0}}>
                                                            { Image("fungus", filteredFungus.id, 60)}
                                                        </Col>
                                                        <Col className="align-middle text-start">
                                                            <div className="fw-bold">{filteredFungus.commonName}</div>
                                                            <div className="fst-italic">{filteredFungus.binomialName}</div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </ListGroup.Item>
                                        ))
                                }
                                </ListGroup>
                        </Card>
                        </Col></Row>
                    </Container>
                </div>
                <div sm={{span:12}} lg={{span:6}} className="w-100">
                <Container>
                    <Row><Col>
                        <Card id="tableTwo">
                        <Card.Header>
                            <Container className="dflex">
                            <Row className="align-items-center">
                                <Col className="text-start">
                                    Select
                                </Col>
                                <Col className="align-items-center">
                                    <InputGroup size="sm" >
                                            <Form.Control 
                                                aria-label="Small" 
                                                id="searchBoxTwo"
                                                placeholder="Filter" 
                                                key="searchBoxTwo"
                                                value={searchTermTwo} 
                                                onChange={handleChangeTwo}
                                                >
                                            </Form.Control>
                                            <InputGroup.Text onClick={resetSearchTermTwo}>
                                                {<Backspace/>}
                                            </InputGroup.Text>
                                    </InputGroup>
                                </Col></Row></Container>
                        </Card.Header>
                            <ListGroup variant="flush" id="searchResultsTableTwo">
                            {   props.fungi.length === 0
                                ? getLoader()
                                :   searchResultsTwo.map(filteredFungus => (
                                        <ListGroup.Item action key={"2" + filteredFungus.id}  id={filteredFungus.id} onClick={handleClickTwo}
                                            className={ (selectionTwo === filteredFungus.id) ? "selected highlighted border-bottom-0" : (selectionTwo > 0 ) ? "hidden" : "" }>
                                            <Container>
                                            <Row className="align-items-center align-middle">
                                                <Col className="text-end" xs={{span:3, offset:0}} sm={{span:3, offset: 1}} md={{span:3, offset:0}}>
                                                    { Image("fungus", filteredFungus.id, 60)}
                                                </Col>
                                                <Col className="align-middle text-start"
                                                    xs={9} sm={8} md={9}>
                                                    <div className="fw-bold">{filteredFungus.commonName}</div>
                                                    <div className="fst-italic">{filteredFungus.binomialName}</div>
                                                </Col>
                                            </Row>
                                            </Container>
                                        </ListGroup.Item>
                            ))}
                            </ListGroup>
                        </Card>
                    </Col></Row>
                    </Container>
                </div>        
            </div>    
            <div  className="text-center p-3 w-100">
                

                {<CompareButton/>}

                <Modal show={showCompare} onHide={handleCloseCompare}>
                    <Modal.Header closeButton  className="bg-light">
                        <Modal.Title><Container><Row><Col>
                            Compare Fungi
                        </Col></Row></Container></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container><Row><Col>
                            {<CompareTable props={props} fungusIdOne={selectionOne} fungusIdTwo={selectionTwo}/>}
                        </Col></Row></Container>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Compare;