import { Link } from "react-router-dom";

function Landing({props}){

    return (
        <div className="vh-100 d-flex text-center text-black justify-content-center align-items-center landingBox">
            <br/>
            <div>
                    <h1 className="landingTitle fw-bold">AgaricId</h1>
                    <h2  className="landingText">
                        Western Australian<br/>
                        Mushroom Identifier
                    </h2>
                    <h5>
                    <p></p>
                    <Link className="landingLinks" to="/identify">Identify by selecting observed traits</Link> {' '}
                    <br/>            <p/>
                    <Link className="landingLinks" to="/browse">Browse the collection</Link> {' '}
                    <br/>             <p/>
                    <Link className="landingLinks" to="/compare">Compare species</Link> {' '}
                    </h5>
            </div>
        </div>
    );
}

export default Landing;