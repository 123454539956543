import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Card, ListGroup, Modal, InputGroup, Form, Spinner} from 'react-bootstrap';
import { Backspace } from 'react-bootstrap-icons';
import Image from './Image';
import IdentifyTable from './IdentifyTable';


function Browse({props}) {

  useEffect(() => {
    props.setShowLanding(false);
  })

  const[ showInfo, setShowInfo ] = useState(false);
  const[ showId, setShowId ] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {

    const results = props.fungi.filter(fungus => 
        fungus.commonName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fungus.binomialName.toLowerCase().includes(searchTerm.toLowerCase())
        );

    let sortedResults;
    if(props.sortByCommonName){
      sortedResults = results.sort((a, b) => {
        if (String(a.commonName) < String(b.commonName)) return -1;
        if (String(a.commonName) > String(b.commonName)) return 1;
        return 0;
      });
    }
    else {
      sortedResults = results.sort((a, b) => {
        if (String(a.binomialName) < String(b.binomialName)) return -1;
        if (String(a.binomialName) > String(b.binomialName)) return 1;
        return 0;
      });
    }

    setSearchResults(sortedResults);

  }, [searchTerm, props.fungi, props.sortByCommonName]);


  function handleShowInfo (fungiId) {
    setShowId(fungiId)
    setShowInfo(true);
  }

  function handleCloseInfo () {
    setShowId(0);
    setShowInfo(false);
  }

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetSearchTerm = () => {
    setSearchTerm("");
  }

  const getLoader = () => {
    return (
      <div className="d-flex justify-content-center p-3">
          <Spinner animation="border" />
      </div> 
    )
  }

  return(
    <div id="resultsBody" key="resultsBody" className="bg-white mb-4 pt-5 mt-4">

      <Container>
        <Row><Col md={{offset:2, span: 8}}>
          <Card>
            <Card.Header>
              <Container className="dflex">
              <Row className="align-items-center">
                  <Col className="text-start">
                      Browse All
                  </Col>
                  <Col className="align-items-center">
                      <InputGroup size="sm">
                          <Form.Control 
                              aria-label="Small" 
                              placeholder="Filter" 
                              value={searchTerm} 
                              onChange={handleChange}
                              >
                          </Form.Control>
                          <InputGroup.Text onClick={resetSearchTerm} >
                              {<Backspace/>}
                          </InputGroup.Text>
                      </InputGroup>
                  </Col></Row></Container>
              </Card.Header>
            <ListGroup variant="flush">

            {props.fungi.length === 0
              ? getLoader()
              : 
                
              searchResults.map(filteredFungus => (
                  <ListGroup.Item action key={filteredFungus.id} onClick={() => handleShowInfo(filteredFungus.id)}>
                  <Container className="dflex">
                  <Row className="align-items-center align-middle">
                    <Col className="text-end" xs={{span:3, offset:0}} sm={{span:3, offset: 1}}>
                      {Image("fungus", filteredFungus.id, 60)}
                    </Col>
                    <Col className="align-middle text-start">
                      <div className="fw-bold">{filteredFungus.commonName}</div>
                      <div className="fst-italic">{filteredFungus.binomialName}</div>
                    </Col>
                  </Row>
                  </Container>
                  </ListGroup.Item>
                ))
              
              
              }



            </ListGroup>
          </Card>
        </Col></Row>
      </Container>



          {props.fungi.filter(fungus => showId === (fungus.id)).map(filteredFungus => (
            <Modal show={showInfo} onHide={handleCloseInfo} key={filteredFungus.id}>
              <Modal.Header closeButton className="bg-light">
                <Modal.Title>
                <span>{filteredFungus.commonName}</span>
                <br/>
                <h6 className="fst-italic">{filteredFungus.binomialName}</h6>
                </Modal.Title> 
              </Modal.Header>
              <Modal.Body>
                <Container>

                  <Row><Col>
                    <div className="imgContainer">
                      {Image("fungus", filteredFungus.id, 250)}
                    </div>
                  </Col></Row>

                  <Row><Col>
                    {<IdentifyTable props={props} fungusId={filteredFungus.id}/>}
                  </Col></Row>

                </Container>
              </Modal.Body>
            </Modal>
          ))}

    </div>
  )
}

export default Browse;